<template>
  <b-card>
    <!-- form -->
    <b-badge v-if="inReview" variant="warning"> InReview </b-badge>
    <b-badge v-if="reviewed" variant="success"> Reviewed </b-badge>
    <b-badge v-if="noDocs" variant="danger"> no Docs for user </b-badge>

    <validation-observer ref="userDocuments">
      <b-form>
        <b-row>
          <!-- bio -->

          <!-- Country -->
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Researches type"
              rules="required"
            >
              <b-form-group
                label-for="Researches Types"
                label="Researches Type"
              >
                <v-select
                  id="researchesList"
                  v-model="researchesTypes"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="Researches type"
                  :options="researchesTypesOption"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="6">
            <b-form-group v-if="requireDocuments != 'class_Research'">
              <label class="d-inline-block text-sm-left mr-50"
                >certificate saying they can conduct research on people</label
              >
              <validation-provider
                #default="{ errors }"
                name="certificate saying they can conduct research on people"
                rules="required"
              >
                <b-form-file
                  v-model="fileDoc1"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".xlsx, .xls, .pdf,.docx"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group v-if="requireDocuments != 'class_Research'">
              <label class="d-inline-block text-sm-left mr-50"
                >A certificate saying they can do a certain research study
              </label>
              <validation-provider
                #default="{ errors }"
                name="A certificate saying they can do a certain research study"
                rules="required"
              >
                <b-form-file
                  v-model="fileDoc2"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".xlsx, .xls, .pdf, .docx"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--/ Country -->
          <b-col cols="12">
            <b-form-group
              label="Type of psychologist"
              label-for="type-psychologist-area"
            >
              <validation-provider
                #default="{ errors }"
                name="research data"
                rules="required"
              >
                <b-form-textarea
                  id="type-psychologist-area"
                  v-model="researchData"
                  rows="4"
                  placeholder="Your research data here..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ bio -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="inReview"
              variant="primary"
              class="mt-1 mr-1"
              @click="handleOkUpdateUser"
            >
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormFile,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BBadge,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      inReview: false,
      reviewed: false,
      noDocs: true,
      researchesTypes: "Psychologist & class Research",
      researchData: "",
      countryOption: ["USA", "India", "Canada"],
      researchesTypesOption: [
        "Psychologist & class_Research",
        "Psychologist",
        "class_Research",
      ],
      fileDoc1: null,
      fileDoc2: null,
      cert1: null,
      cert2: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: "US",
      },
    };
  },
  computed: {
    requireDocuments() {
      return this.researchesTypes;
    },
  },
  mounted() {

    this.getUser();
  },
  methods: {
    async getUser() {

      const user = await Backendless.UserService.getCurrentUser();
      if (user.reviewedDocs == "inReview") {
        this.inReview = true;
        this.noDocs = false;
      }
      if (user.reviewedDocs == "reviewed") {
        this.reviewed = true;
        this.noDocs = false;
      }
    },
    makeId(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }

      const currentTimestamp = moment(new Date()).format(
        "MM_DD_YYYY_h_mm_ss_SSS"
      );
      return result + currentTimestamp;
    },
    handleOkUpdateUser() {

      const that = this;
      // Trigger submit handler
      this.$refs.userDocuments
        .validate()
        .then(async (success) => {
          if (success) {
            // eslint-disable-next-line

            alert("form submitted!");
            this.cert1 = await Backendless.Files.upload(
              this.fileDoc1,
              `/userDocs/${this.makeId(10)}`
            );
            this.cert2 = await Backendless.Files.upload(
              this.fileDoc2,
              `/userDocs/${this.makeId(10)}`
            );

            const _doc1 = { Document: this.cert1.fileURL, type: "cert1" };
            const _doc2 = { Document: this.cert2.fileURL, type: "cert2" };

            const doc1 = await Backendless.Data.of("documents").save(_doc1);
            const doc2 = await Backendless.Data.of("documents").save(_doc2);

            const user = await Backendless.UserService.getCurrentUser();


            Backendless.Data.of("Users")
              .setRelation(user, "documents", [doc1, doc2])
              .then(async (count) => {

                this.reviewed = true;
                this.inReview = false;
                this.noDocs = false;
                console.log("relation has been set");
                that.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Documents added Successfully",
                    icon: "BellIcon",
                    // text: this.username,
                    variant: "success",
                  },
                });
              })
              .catch((error) => {


                console.log(`server reported an error - ${error.message}`);
              });
          }
        })
        .catch((error) => {

          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
