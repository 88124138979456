<template>
  <b-card>
    <!-- media -->
    <b-media>
      <b-media-aside>
        <b-link>
          <b-img
            v-if="avatar != ''"
            ref="previewEl"
            rounded
            :src="avatar"
            height="80"
          />
          <b-img
            v-else
            ref="previewEl"
            rounded
            :src="require('@/assets/images/logo/logo.png')"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="updateUser">
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="First Name"
              label-for="first-name"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  v-model="FirstName"
                  placeholder="First name"
                  name="FirstName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Last Name"
              label-for="last-name"
            >
              <validation-provider
                #default="{ errors }"
                name="last Name"
                rules="required"
              >
                <b-form-input
                  v-model="LastName"
                  name="lastName"
                  placeholder="Last Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="mail"
                rules="required|email"
              >
                <b-form-input
                  v-model="myEmail"
                  name="email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="University"
              label-for="account-university"
            >
              <validation-provider
                #default="{ errors }"
                name="University"
                rules="required"
              >
                <b-form-input
                  v-model="University"
                  name="University"
                  placeholder="University name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- alert -->
          <!-- <b-col cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link"> Resend confirmation </b-link>
            </div>
          </b-alert>
        </b-col> -->
          <!--/ alert -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="handleOkUpdateUser"
            >
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { required, email } from '@validations'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      avatar: '',
      FirstName: '',
      LastName: '',
      myEmail: '',
      University: '',
      currentUser: null,
      profileFile: null,
    }
  },
  mounted() {
    this.getUsername()
  },
  methods: {
    resetForm() {
      // this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
    handleOkUpdateUser() {

      // Trigger submit handler
      const that = this
      this.$refs.updateUser.validate().then(success => {
        if (success) {
          // eslint-disable-next-line

          // alert("form submitted!");

          const user = this.currentUser
          user.University = this.University
          user.FirstName = this.FirstName
          user.LastName = this.LastName
          user.email = this.myEmail

          Backendless.UserService.update(user)
            .then(updatedUser => {

              that.currentUser = updatedUser
            })
            .catch(error => {

              console.log('error', error)
            })
        }
      })
    },
    getUsername() {
      const that = this


      Backendless.UserService.getCurrentUser()
        .then(user => {
          this.currentUser = user
          if (user.FirstName != null && user.FirstName != '') {
            this.FirstName = user.FirstName
            this.LastName = user.LastName
            this.myEmail = user.email
            this.University = user.University
          } else {
            FirstName = ''
          }
        })
        .catch(error => {

          console.error(`can't get user ${error}`)
        })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
