<template>
  <b-card>
    <!-- form -->
    <ValidationObserver
      v-slot="{ invalid, handleSubmit, reset }"
      ref="observer"
    >
      <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="onReset">
        <!--
        <b-row>

          <b-col md="6">
            <b-form-group label="Old PIN" label-for="account-old-password">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-old-password"
                  v-model="passwordValueOld"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  placeholder="Old Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

        </b-row> -->

        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Pin Code"
              vid="password"
              rules="digits:6|required"
            >
              <b-form-group label-for="account-new-password" label="New PIN">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    maxlength="6"
                    name="new-password"
                    placeholder="New PIN"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!--/ new password -->

        <!-- retype password -->
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Password Confirmation"
              rules="required|confirmed:password"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Retype New PIN"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    maxlength="6"
                    name="retype-password"
                    placeholder="New PIN"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <!--/ retype password -->

        <b-row>
          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
              :disabled="invalid"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    onSubmit() {
      Backendless.UserService.getCurrentUser()
        .then((user) => {
          user.password = this.newPasswordValue;
          Backendless.UserService.update(user)
            .then((updatedUser) => {
              console.log(updatedUser);
              this.$toast.success("PIN Updated Successfully");
              this.onReset();
            })
            .catch((error) => {
              console.log(error);
              this.$toast.error(error.message);
            });
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error.message);
        });
    },
    onReset() {
      this.passwordValueOld = "";
      this.newPasswordValue = "";
      this.RetypePassword = "";
      this.$refs.observer.reset();
    },
  },
};
</script>
